import React, { useState, useEffect } from 'react';
import api from '../api';  // Use the custom Axios instance

const Details = ({ selectedType, selectedName }) => {
  const [schema, setSchema] = useState([]);
  const [definition, setDefinition] = useState('');
  const [records, setRecords] = useState([]);

  useEffect(() => {
    if (selectedType === 'table') {
      // Fetch table schema
      api.get(`/tables/schema/${selectedName}`).then((response) => {
        setSchema(response.data);
      });

      // Fetch table records
      api.get(`/tables/data/${selectedName}?page=1&perPage=10`).then((response) => {
        setRecords(response.data);
      });
    } else if (selectedType === 'view') {
      // Fetch view schema
      api.get(`/views/schema/${selectedName}`).then((response) => {
        setSchema(response.data);
      });

      // Fetch view definition
      api.get(`/views/definition/${selectedName}`).then((response) => {
        setDefinition(response.data.definition);
      });

      // Fetch view records
      api.get(`/views/data/${selectedName}?page=1&perPage=10`).then((response) => {
        setRecords(response.data);
      });
    }
  }, [selectedType, selectedName]);

  return (
    <div className="details">
      {/* Schema for Tables or Views */}
      <h3>Schema for {selectedType === 'table' ? 'Table' : 'View'}: {selectedName}</h3>
      <ul>
        {schema.map((column) => (
          <li key={column.COLUMN_NAME}>
            {column.COLUMN_NAME} ({column.DATA_TYPE})
          </li>
        ))}
      </ul>

      {/* View Definition (for Views only) */}
      {selectedType === 'view' && (
        <>
          <h3>Definition for View: {selectedName}</h3>
          <pre>{definition}</pre>
        </>
      )}

      {/* Records for Tables or Views in a Table Format */}
      <h3>Records</h3>
      {records.length > 0 ? (
        <div className="scrollable-table">
          <table className="records-table">
            <thead>
              <tr>
                {/* Dynamically create table headers based on the keys in the first record */}
                {Object.keys(records[0]).map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {/* Create table rows by mapping through the records */}
              {records.map((record, index) => (
                <tr key={index}>
                  {Object.values(record).map((value, i) => (
                    <td key={i}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No records available.</p>
      )}
    </div>
  );
};

export default Details;
