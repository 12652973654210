import React, { useState, useEffect } from 'react';
import api from '../api';  // Use the custom Axios instance

const Explorer = ({ onSelect }) => {
  const [tables, setTables] = useState([]);
  const [views, setViews] = useState([]);
  const [expanded, setExpanded] = useState({ tables: false, views: false });  // Track expanded state
  const [loadingTables, setLoadingTables] = useState(true);  // Loading state for tables
  const [loadingViews, setLoadingViews] = useState(true);  // Loading state for views
  const [error, setError] = useState(null);  // Error state

  useEffect(() => {
    // Fetch tables with record counts
    setLoadingTables(true);
    api.get('/tables/count')
      .then((response) => {
        setTables(response.data);
        setLoadingTables(false);  // Set loading to false once data is loaded
      })
      .catch((error) => {
        console.error('Error fetching tables:', error);
        setError('Error fetching tables');
        setLoadingTables(false);  // Set loading to false even if there's an error
      });

    // Fetch views without record counts first
    setLoadingViews(true);
    api.get('/views')
      .then((response) => {
        setViews(response.data);  // Load views without record counts
        setLoadingViews(false);

        // After loading the views, fetch record counts for each view asynchronously
        response.data.forEach((view) => {
          api.get(`/views/${view.viewName}/count`)
            .then((countResponse) => {
              setViews((prevViews) =>
                prevViews.map((v) =>
                  v.viewName === view.viewName
                    ? { ...v, recordCount: countResponse.data.recordCount }
                    : v
                )
              );
            })
            .catch((error) => {
              console.error(`Error fetching record count for view ${view.viewName}:`, error);
            });
        });
      })
      .catch((error) => {
        console.error('Error fetching views:', error);
        setError('Error fetching views');
        setLoadingViews(false);
      });
  }, []);

  // Function to handle expanding/collapsing of categories (Tables/Views)
  const toggleExpand = (category) => {
    setExpanded((prevState) => ({
      ...prevState,
      [category]: !prevState[category]  // Toggle the category's expanded state
    }));
  };

  return (
    <div className="explorer">
      <h3>Database Explorer</h3>

      {/* Loading indicator or error for tables */}
      {loadingTables ? (
        <div>Loading tables... Please wait.</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <div>
          <div onClick={() => toggleExpand('tables')} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
            Tables ({tables.length})
          </div>
          {expanded.tables && (
            <div style={{ paddingLeft: '20px' }}>
              {tables.map((table) => (
                <div
                  key={table.tableName}
                  className="table-item"
                  onClick={() => onSelect('table', table.tableName)}
                  style={{ cursor: 'pointer', paddingLeft: '10px' }}
                >
                  {table.tableName} ({table.recordCount} records)
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {/* Loading indicator or error for views */}
      {loadingViews ? (
        <div>Loading views... Please wait.</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <div>
          <div onClick={() => toggleExpand('views')} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
            Views ({views.length})
          </div>
          {expanded.views && (
            <div style={{ paddingLeft: '20px' }}>
              {views.map((view) => (
                <div
                  key={view.viewName}
                  className="view-item"
                  onClick={() => onSelect('view', view.viewName)}
                  style={{ cursor: 'pointer', paddingLeft: '10px' }}
                >
                  {view.viewName} ({view.recordCount !== undefined ? `${view.recordCount} records` : 'Loading...'})
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Explorer;
