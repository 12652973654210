import axios from 'axios';

// Use environment variable for the API URL, with a fallback to localhost
const apiUrl = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: apiUrl,
});

export default api;
