import React, { useState } from 'react';
import Explorer from './components/Explorer';
import Details from './components/Details';
import './App.css';

function App() {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedName, setSelectedName] = useState(null);

  const handleSelect = (type, name) => {
    setSelectedType(type);
    setSelectedName(name);
  };

  return (
    <div className="app">
      <div className="sidebar">
        <Explorer onSelect={handleSelect} />
      </div>
      <div className="content">
        {selectedType && selectedName && (
          <Details selectedType={selectedType} selectedName={selectedName} />
        )}
      </div>
    </div>
  );
}

export default App;
